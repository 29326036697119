import React, { useEffect, useState } from 'react';
import fetchLootboxes from '../api/getLootboxes';
import { LootboxType } from '../types/lootboxes';

const Lootboxes: React.FC = () => {
  const [lootboxes, setLootboxes] = useState<LootboxType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadLootboxes = async () => {
      try {
        const data = await fetchLootboxes();
        setLootboxes(data);
      } catch (err) {
        setError('Failed to fetch lootboxes');
      } finally {
        setLoading(false);
      }
    };

    loadLootboxes();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  function getLootboxName(i: string){
    switch (i){
        case '1':
            return 'COMMON';
            break;
        case '2':
            return 'RARE'
            break;
        case '3':
            return 'EPIC'
            break;
        case '4':
            return 'LEGENDARY'
            break;
        default:
            return 'NONAME'
    }
  }

  

  return (
    <div style={{backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '5px' ,borderRadius: '6px'}}>
      <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.25)' }}>Lootboxes: last sold prices</div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.25)' }}>(MAGIC)</div>
      <ul>
        {lootboxes.map((lootbox) => (
          <li key={lootbox.id}>
            {getLootboxName(lootbox.lootboxId)}: {lootbox.pricePerItem/1e18}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Lootboxes;