import { ethers } from 'ethers';
import axios from 'axios';
import { loadBattlePvpData } from './getAdvRenownByDate'; // Import procedury pobierającej renownSum

const API_KEY = 'XCAJG2DVHPAPIY116GAFR2UI3VCYKC539E';
const FIGHT_CONTRACT_ADDRESS = '0x2cfcaff3289142E79173B856293D6128B6bD05c6';
const ANIMA_CONTRACT_ADDRESS = '0xccd05a0fcfc1380e9da27862adb2198e58e0d66f';
const ARBISCAN_API_URL = 'https://api.arbiscan.io/api';

interface TransactionData {
  date: string;
  animaSum: number;
  renownSum: number; // Dodajemy pole renownSum
  transactionFeeSum: number;
}

const fetchCurrentEthPrice = async (): Promise<number> => {
  try {
    const response = await axios.get(`${ARBISCAN_API_URL}`, {
      params: {
        module: 'stats',
        action: 'ethprice',
        apikey: API_KEY,
      },
    });
    return parseFloat(response.data.result.ethusd);
  } catch (error) {
    console.error('Error fetching ETH price from Arbiscan:', error);
    throw new Error('Failed to fetch ETH price');
  }
};

export const fetchFightsData = async (address: string): Promise<TransactionData[]> => {
  const currentEthPrice = await fetchCurrentEthPrice();
  const oneMonthAgo = Math.floor(Date.now() / 1000) - (31 * 24 * 60 * 60); // 31 dni temu

  const fightTxUrl = `${ARBISCAN_API_URL}?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=10000&sort=desc&apikey=${API_KEY}`;
  const animaTxUrl = `${ARBISCAN_API_URL}?module=account&action=tokentx&address=${address}&contractaddress=${ANIMA_CONTRACT_ADDRESS}&page=1&offset=10000&startblock=0&endblock=99999999&sort=desc&apikey=${API_KEY}`;
  
  console.log("Fight Tx API URL:", fightTxUrl);
  console.log("Anima Tx API URL:", animaTxUrl);

  const fightResponse = await axios.get(fightTxUrl);
  const animaResponse = await axios.get(animaTxUrl);

  if (fightResponse.data.status !== "1" || !fightResponse.data.result || animaResponse.data.status !== "1" || !animaResponse.data.result) {
    console.error("Error fetching data:", fightResponse.data.message, animaResponse.data.message);
    return [];
  }

  const fightTransactions = fightResponse.data.result.filter((tx: any) =>
    Number(tx.timeStamp) >= oneMonthAgo &&
    tx.txreceipt_status === "1" &&
    tx.functionName === "fight(tuple _request)"
  );

  const animaTransactions = animaResponse.data.result.filter((tx: any) =>
    Number(tx.timeStamp) >= oneMonthAgo &&
    tx.from === '0x0000000000000000000000000000000000000000'
  );

  console.log("Filtered Fight Transactions:", fightTransactions);
  console.log("Filtered Anima Transactions:", animaTransactions);

  // Pobierz dane renownSum z poprzedniej procedury
  const renownData = await loadBattlePvpData(address);
  
  const transactionData: { [key: string]: { animaSum: number; renownSum: number; transactionFeeSum: number } } = {};

  for (const tx of fightTransactions) {
    const animaTx = animaTransactions.find((animaTx: any) => animaTx.hash === tx.hash);

    if (animaTx) {
      const date = new Date(Number(tx.timeStamp) * 1000).toISOString().split('T')[0]; // Format: YYYY-MM-DD
      if (!transactionData[date]) {
        transactionData[date] = { animaSum: 0, renownSum: 0, transactionFeeSum: 0 };
      }

      transactionData[date].animaSum += Number(ethers.utils.formatUnits(animaTx.value, 18));
      transactionData[date].transactionFeeSum += (Number(ethers.utils.formatEther(tx.gasUsed)) * currentEthPrice) * 1e7;
    }
  }

  // Przetwarzanie transakcji i dodawanie renownSum dla istniejących dni
  const result: TransactionData[] = Object.entries(transactionData)
    .map(([date, { animaSum, transactionFeeSum }]) => {
      // Znajdź wartość renownSum dla tego dnia
      const renownRecord = renownData.find((r) => r.date === date);
      return {
        date,
        animaSum,
        renownSum: renownRecord ? renownRecord.renownSum : 0, // Jeśli brak renownRecord, dajemy 0
        transactionFeeSum
      };
    });

  return result;
};











