import axios from 'axios';
import { API_BASE_URL } from '../constants/constants';
import { currentEpoch } from '../utils/utils';

// Typ dla zwracanych danych (data i suma renown)
interface RenownSummary {
  date: string;  // Data w formacie YYYY-MM-DD
  renownSum: number;  // Suma attackerRenown dla danego dnia
}

// Funkcja pomocnicza do konwersji epoki na datę (bez godziny)
const epochToDate = (fightEpoch: number): string => {
    const date = new Date(fightEpoch * 24 * 60 * 60 * 1000); // Konwersja dni od epoki Unix (w ms)
    return date.toISOString().split('T')[0]; // Zwracamy datę w formacie YYYY-MM-DD
  };

export const loadBattlePvpData = async (creatorAddress: string): Promise<RenownSummary[]> => {
  const pageSize = 1000;
  let offset = 0;
  let hasMoreData = true;
  let allData: { fightEpoch: number; attackerRenown: string }[] = []; // attackerRenown as string
  const addressLowerCase = creatorAddress ? (`0x${creatorAddress.slice(2).toLowerCase()}` as `0x${string}`) : undefined;

  while (hasMoreData) {
    try {
      const response = await axios.post(API_BASE_URL, {
        operationName: 'advRenowns',
        query: `
          query advRenowns($first: Int!, $skip: Int!, $creator: String!, $epochStart: Int!) {
            battlePvps(first: $first, skip: $skip, where: {
              creator: $creator,
              fightEpoch_gt: $epochStart
            }, orderBy: fightEpoch, orderDirection: asc) {
              fightEpoch
              attackerRenown
            }
          }
        `,
        variables: {
          first: pageSize,
          skip: offset,
          creator: addressLowerCase,
          epochStart: currentEpoch() - 31 // Ograniczenie do ostatnich 31 dni
        },
      });

      const responseData = response.data;

      // Logowanie danych pobranych przez API
      console.log('Raw API response data:', responseData);

      if (responseData && responseData.data && responseData.data.battlePvps) {
        const fetchedData = responseData.data.battlePvps;

        if (fetchedData.length < pageSize) {
          hasMoreData = false;
        }

        allData = [...allData, ...fetchedData];
        offset += pageSize;
      } else {
        console.error('Unexpected GraphQL response structure:', responseData);
        hasMoreData = false;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      hasMoreData = false;
    }
  }

  // Grupowanie danych po dacie i sumowanie attackerRenown po konwersji na liczbę i podziale przez 1000
  const groupedData: { [date: string]: number } = allData.reduce((acc, item) => {
    const date = epochToDate(item.fightEpoch);  // Konwersja epoki na datę (YYYY-MM-DD)
    const renownAsNumber = Number(item.attackerRenown) / 1000; // Konwersja na liczbę i podział przez 1000
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date] += renownAsNumber;  // Sumowanie renown po podzieleniu przez 1000 dla danej daty
    return acc;
  }, {} as { [date: string]: number });

  // Logowanie po przetworzeniu danych (transpozycji)
  console.log('Grouped data after transposition:', groupedData);

  // Konwersja obiektu na listę { date, renownSum }
  const result: RenownSummary[] = Object.keys(groupedData).map(date => ({
    date,
    renownSum: groupedData[date]
  }));

  // Logowanie ostatecznego wyniku przed zwróceniem
  console.log('Final result (date, renownSum):', result);

  return result;
};




