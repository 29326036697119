import axios from 'axios';
//const API_BASE_URL='https://staging.getanima.org';
import { API_BASE_URL } from '../constants/constants';
import { PERIPHERALS, PERIPHERALS_BATTLE, Rarity, rarityThresholds, masteryCoefficients} from '../constants/constants'; 
import { AdventurerType, InventoryItem, SigmoidConfig, CurveConfig, RangeConfig} from '../types/adventurer';

//import { currentEpoch } from '../utils/utils';

/*
 statBoostConfig.shiftY = 50000;
    statBoostConfig.leftCurve.ascending = true;
    statBoostConfig.leftCurve.yAdjustment = 0;
    statBoostConfig.leftCurve.rangeY = 50000;
    statBoostConfig.leftCurve.steepness = 400000;
    statBoostConfig.leftCurve.steepnessCoefficient = 125000;
    statBoostConfig.rightCurve.ascending = true;
    statBoostConfig.rightCurve.yAdjustment = -50000;
    statBoostConfig.rightCurve.rangeY = 100000;
    statBoostConfig.rightCurve.steepness = 200000;
    statBoostConfig.rightCurve.steepnessCoefficient = 150000;
*/



const statBoostConfig: SigmoidConfig = {
  shiftY: 50,
  leftCurve: {
    ascending: true,
    yAdjustment: 0,
    rangeY: 5,
    steepness: 4000,
    steepnessCoefficient: 1250,
  },
  rightCurve: {
    ascending: true,
    yAdjustment: -5,
    rangeY: 10,
    steepness: 2000,
    steepnessCoefficient: 1500,
  }
};


const calculateMastery = (adventurerLevel: number, peripheralRarity: Rarity): number => {
  const threshold = rarityThresholds[peripheralRarity];
  const coefficients = masteryCoefficients[peripheralRarity];

  if (adventurerLevel === threshold) {
    return 1;
  } else if (adventurerLevel < threshold) {
    const diff = threshold - adventurerLevel;
    return 1 - (1 * diff) / (diff + coefficients.reverseCoefficient);
  } else {
    const diff = adventurerLevel - threshold;
    return 1 + (1 * diff) / (diff + coefficients.coefficient);
  }
};

const sigmoid = (config: SigmoidConfig, range: RangeConfig, x: number): number => {
  if (x >= range.mid) {
    return (
      calculateCurve(config.rightCurve, range.max, x - range.mid, -1) + config.shiftY
    );
  } else {
    return calculateCurve(config.leftCurve, range.min, range.mid - x, 1) + config.shiftY;
  }
};

const SIGNED_ONE_HUNDRED = 100;

const calculateCurve = (config: CurveConfig, rangeX: number, x: number, sign: number): number => {
  const SIGNED_ONE_HUNDRED_SQUARE = SIGNED_ONE_HUNDRED * SIGNED_ONE_HUNDRED;
  //x+=1;

  if (!config.ascending) {
    sign *= -1;
  }
  return ((
    config.yAdjustment +
    ((SIGNED_ONE_HUNDRED +
      (sign *
        ((rangeX * SIGNED_ONE_HUNDRED_SQUARE) /
          (rangeX * SIGNED_ONE_HUNDRED + x * config.steepness) -
          SIGNED_ONE_HUNDRED)) *
        config.steepnessCoefficient) /
      SIGNED_ONE_HUNDRED) *
      config.rangeY
  ) / SIGNED_ONE_HUNDRED);
};


export const calculateBattlePower = (myAdventurer: AdventurerType, equip: boolean): number => {
  const adventurer = { ...myAdventurer };
  let battlePower = 20 * adventurer.level;
  battlePower += Number(adventurer.strength) + Number(adventurer.dexterity) + Number(adventurer.constitution) + Number(adventurer.intelligence) + Number(adventurer.wisdom) + Number(adventurer.charisma);

  console.log('base BP:', battlePower);
  const adventurerLevel = adventurer.level;
  let peripheralIds = [adventurer.slot4, adventurer.slot3, adventurer.slot2, adventurer.slot1];
  
  if (equip) peripheralIds = [String(adventurer.slot4equip || adventurer.slot4), String(adventurer.slot3equip || adventurer.slot3), String(adventurer.slot2equip  || adventurer.slot2), String(adventurer.slot1equip  || adventurer.slot4)];

  const levelBoosts = peripheralIds.map((peripheralId) => {
    const metadata = PERIPHERALS[peripheralId];
    if (!metadata) {
      console.error(`Peripheral with id ${peripheralId} not found in PERIPHERALS`);
      return 1; // Default mastery value
    }
    const rarity = metadata.attributes.find(attr => attr.trait_type === 'Rarity')?.value as Rarity || Rarity.Common;
    return calculateMastery(adventurerLevel, rarity);
  });

  console.log('Adventurer Level:', adventurerLevel, " ID:", adventurer.tokenId);
  console.log('Peripheral IDs:', peripheralIds);
  console.log('Level Boosts:', levelBoosts);

  peripheralIds.forEach((peripheralId, index) => {
    let curBP = 0;
    const boosts = PERIPHERALS_BATTLE[peripheralId]?.boosts || [];
    boosts.forEach((boostContainer) => {
      const boost = boostContainer.boost;
      let traitBoost = 1; // Default trait boost
      switch (boost.stat) {
      case 'battlePower':
            if (boost.affinity) {
              const affinityValue = boost.affinity.stats.reduce(
                (sum, item) => sum + (Number(adventurer[item.stat as keyof AdventurerType]) || 0) * item.weight,
                0
              ) || 0;
              console.log(`Peripheral ID: ${peripheralId}, Boost Value: ${boost.value}, Affinity Value (x): ${affinityValue}, Range: ${JSON.stringify(boost.affinity.range)}`);
              traitBoost = sigmoid(statBoostConfig, boost.affinity.range, affinityValue) / SIGNED_ONE_HUNDRED;
              
            }

            console.log(` Trait Boost: ${traitBoost}, Level Boost: ${levelBoosts[index]}`);
            const boostContribution = boost.value * traitBoost * levelBoosts[index];
            battlePower += Number(boostContribution);
            curBP += Number(boostContribution);
            console.log(`Boost Contribution: ${boostContribution}`);
            break;
      case 'strength':
            adventurer['strength']+=boost.value;
            battlePower+=boost.value;
            break;
      case 'dexterity':
            adventurer['dexterity']+=boost.value;
            battlePower+=boost.value;
        break;
      case 'constitution':
            adventurer['constitution']+=boost.value;
            battlePower+=boost.value;
            break;
      case 'intelligence':
            adventurer['intelligence']+=boost.value;
            battlePower+=boost.value;
            break;
      case 'wisdom':
            adventurer['wisdom']+=boost.value;
            battlePower+=boost.value;
            break;
      case 'charisma':
            adventurer['charisma']+=boost.value;
            battlePower+=boost.value;
            break;
      }
    });
    console.log({ peripheralId }, ' Periph BP=', { curBP });
  });

  console.log('Final Battle Power:', battlePower);
  return battlePower;
};


const graphEndpoint = API_BASE_URL;
const rarityPriority: { [key: string]: number } = {
  legendary: 1,
  epic: 2,
  rare: 3,
  common: 4
};

const getRarityPriority = (rarity: string | undefined): number => {
  return rarity ? rarityPriority[rarity.toLowerCase()] ?? 5 : 5;
};



const sortAdventurers = (adventurers: AdventurerType[]): AdventurerType[] => {
  return adventurers.sort((a, b) => {
    if (a.address < b.address) {
      return -1;
    }
    if (a.address > b.address) {
      return 1;
    }
    // Jeśli address są takie same, porównujemy tokenId
    return a.tokenId - b.tokenId;
  });
};



//attackEpoch_lte: "${currentEpoch()}",

export const getAdventurers = async (address: string): Promise<AdventurerType[]> => {

  console.log('graphEndPoint',graphEndpoint);

  let addressLowerCase = address ? (`0x${address.slice(2).toLowerCase()}` as `0x${string}`) : undefined;
  const { data } = await axios.post(graphEndpoint, {
    operationName: "Adventurers",
    query: `
    query Adventurers {
      adventurers(first: 1000, where: { 
        battlePower_not:0,
        renown_not:0,
        owner: "${addressLowerCase}",
      }) {
        id
        address
        tokenId
        createdAt
        owner
        archetype
        profession
        klass
        level
        xp
        hp
        battles
        strength
        dexterity
        intelligence
        charisma
        constitution
        wisdom
        lastBattledAt
        unbound
        renown
        battlePower
        slot1
        slot2
        slot3
        slot4
        slot1Broken
        slot2Broken
        slot3Broken
        slot4Broken
        openSlots
        attackEpoch
        inventory(
          where: {
            tokenAddress: "0x8decc5d594da4e6e2d474cbccaa111081efaca40",
            amount_not:0
          }
        ) {
          tokenId
          amount
        }
      }
    }
    `
  });
  

  const adventurers: AdventurerType[] = data.data.adventurers.map((i: any) => {
    const inventory: InventoryItem[] = i.inventory.map((item: any) => {
      const peripheral = PERIPHERALS[item.tokenId];
      let slot: number | undefined;
      let rarity: string | undefined;

      if (peripheral) {
        const slotAttribute = peripheral.attributes.find(attr => attr.trait_type === 'Slot');
        const rarityAttribute = peripheral.attributes.find(attr => attr.trait_type === 'Rarity');

        if (slotAttribute) {
          switch (slotAttribute.value) {
            case 'Head':
              slot = 1;
              break;
            case 'Chest':
              slot = 2;
              break;
            case 'Hand':
              slot = 3;
              break;
            case 'Accessory':
              slot = 4;
              break;
          }
        }

        if (rarityAttribute) {
          rarity = rarityAttribute.value;
        }
      }

      return {
        ...item,
        slot,
        rarity
      };
    });

    inventory.sort((a, b) => getRarityPriority(a.rarity) - getRarityPriority(b.rarity));

    i.slot1 = i.slot1Broken ? "0" : i.slot1;
    i.slot2 = i.slot2Broken ? "0" : i.slot2;
    i.slot3 = i.slot3Broken ? "0" : i.slot3;
    i.slot4 = i.slot4Broken ? "0" : i.slot4;



    let slot1equip: string | number = 0;
    let slot2equip: string | number = 0;
    let slot3equip: string | number = 0;
    let slot4equip: string | number = 0;
    let slot1equipRarity: string | undefined;
    let slot2equipRarity: string | undefined;
    let slot3equipRarity: string | undefined;
    let slot4equipRarity: string | undefined;

    if (!i.slot1Broken) {slot1equip=i.slot1; slot1equipRarity=i.slot1Rarity;}
    if (!i.slot2Broken) {slot2equip=i.slot2; slot2equipRarity=i.slot2Rarity;}
    if (!i.slot3Broken) {slot3equip=i.slot3; slot3equipRarity=i.slot3Rarity;}
    if (!i.slot4Broken) {slot4equip=i.slot4; slot4equipRarity=i.slot4Rarity;}

    // Nowe pola slotXRarity
    let slot1Rarity: string | undefined;
    let slot2Rarity: string | undefined;
    let slot3Rarity: string | undefined;
    let slot4Rarity: string | undefined;

    inventory.forEach(item => {
      if (item.slot === 1 && item.tokenId !== i.slot1) {
        if (slot1equip === 0 || getRarityPriority(item.rarity) < getRarityPriority(slot1equipRarity)) {
          slot1equip = item.tokenId;
          slot1equipRarity = item.rarity;
        }
      }
      if (item.slot === 2 && item.tokenId !== i.slot2) {
        if (slot2equip === 0 || getRarityPriority(item.rarity) < getRarityPriority(slot2equipRarity)) {
          slot2equip = item.tokenId;
          slot2equipRarity = item.rarity;
        }
      }
      if (item.slot === 3 && item.tokenId !== i.slot3) {
        if (slot3equip === 0 || getRarityPriority(item.rarity) < getRarityPriority(slot3equipRarity)) {
          
          slot3equip = item.tokenId;
          slot3equipRarity = item.rarity;
          if (i.tokenId==5045) console.log('Numer peri dor aov 5045:',slot3equip)
        }
      }
      if (item.slot === 4 && item.tokenId !== i.slot4) {
        if (slot4equip === 0 || getRarityPriority(item.rarity) < getRarityPriority(slot4equipRarity)) {
          slot4equip = item.tokenId;
          slot4equipRarity = item.rarity;
        }
      }

     

      // Przypisywanie wartości slotXRarity na podstawie inventory.key = slotX
      if (item.tokenId == i.slot1) {
        slot1Rarity = item.rarity;
      }
      if (item.tokenId == i.slot2) {
        slot2Rarity = item.rarity;
      }
      if (item.tokenId == i.slot3) {
        slot3Rarity = item.rarity;
      }
      if (item.tokenId == i.slot4) {
        slot4Rarity = item.rarity;
      }
    });

    
    if (i.slot1 !=0 && !i.slot1Broken) {slot1equip=i.slot1; slot1equipRarity=slot1Rarity;}
    if (i.slot2 !=0 && !i.slot2Broken) {slot2equip=i.slot2; slot2equipRarity=slot2Rarity;}
   
    if (i.slot3 !=0 && !i.slot3Broken) {slot3equip=i.slot3; slot3equipRarity=slot3Rarity;}
   
    if (i.slot4 !=0 && !i.slot4Broken) {slot4equip=i.slot4; slot4equipRarity=slot4Rarity;}

    
    

   

    // Konwersja odpowiednich właściwości na liczby
    const strength = parseInt(i.strength, 10);
    const dexterity = parseInt(i.dexterity, 10);
    const intelligence = parseInt(i.intelligence, 10);
    const charisma = parseInt(i.charisma, 10);
    const constitution = parseInt(i.constitution, 10);
    const wisdom = parseInt(i.wisdom, 10);
    const attackEpoch = parseInt(i.attackEpoch, 10);

    const adventurer: AdventurerType = {
      ...i,
      slot1: Number(i.slot1),
      slot2: Number(i.slot2),
      slot3: Number(i.slot3),
      slot4: Number(i.slot4),
      slot1equip: Number(slot1equip),
      slot2equip: Number(slot2equip),
      slot3equip: Number(slot3equip),
      slot4equip: Number(slot4equip),
      slot1equipRarity,
      slot2equipRarity,
      slot3equipRarity,
      slot4equipRarity,
      slot1Rarity,
      slot2Rarity,
      slot3Rarity,
      slot4Rarity,
      addEquipmentToArmoryIds: [],
      addEquipmentToArmoryAmounts: []
    };

    // Wywołaj funkcje obliczające wartości
    let battlePowerAdjusted=i.battlePower;
    let battlePowerEquip=i.battlePower;
    if(i.slot1=='0' && i.slot1Broken || i.slot2=='0' && i.slot2Broken || i.slot3=='0' && i.slot3Broken || i.slot4=='0' && i.slot4Broken) 
      battlePowerAdjusted = Math.round(calculateBattlePower(adventurer,false)*1000);
        
      
    if (i.slot1=='0' && slot1equip!='0' || i.slot2=='0' && slot2equip!='0' || i.slot3=='0' && slot3equip!='0' || i.slot4=='0' && slot4equip!='0')
    battlePowerEquip=Math.round(calculateBattlePower(adventurer,true)*1000);
  else battlePowerEquip=battlePowerAdjusted;

  //if (battlePowerAdjusted>battlePowerEquip) battlePowerEquip=battlePowerAdjusted;

        
      
 
    return {
      ...adventurer,
      battlePowerAdjusted,
      battlePowerEquip
    };
  });

  console.log('adventurers', adventurers); // Dodaj to, aby wypisać całą kolekcję do konsoli
  console.log('get Adventurers from api!');

  return sortAdventurers(adventurers);
};




