import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../constants/constants';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Define the type for props
interface BalanceChartProps {
  userAddress: string;
}

const BalanceChart: React.FC<BalanceChartProps> = ({ userAddress }) => {
  const [selectedChart, setSelectedChart] = useState('All');
  const [chartData, setChartData] = useState<any>(null); // Holds the balance chart data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch balance chart data from the API
  useEffect(() => {
    const fetchBalanceChartData = async () => {
      const lowercasedUserAddress = userAddress.toLowerCase(); // Convert address to lowercase
      console.log(`Fetching data for address: ${lowercasedUserAddress}`); // Log the lowercase address
  
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api?endpoint=loadBChartData&userAddress=${lowercasedUserAddress}`);
  
        if (response.ok) {
          const data = await response.json();
          setChartData(data);
        } else {
          throw new Error('');
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      }
      setLoading(false);
    };
  
    fetchBalanceChartData();
  }, [userAddress]);

  const getChartData = () => {
    if (!chartData) return [];

    const selectedChartData = chartData.find((chart: any) => chart.label === selectedChart);
    if (!selectedChartData) return [];

    // Flatten the data for Recharts
    return selectedChartData.data[0]?.dataPoints.map((_: any, index: number) => {
      const lootboxes = selectedChartData.data.find((s: any) => s.seriesName === 'Lootboxes')?.dataPoints[index]?.y;
      const renown = selectedChartData.data.find((s: any) => s.seriesName === 'Renown')?.dataPoints[index]?.y;
      return {
        x: selectedChartData.data[0].dataPoints[index].x,
        Lootboxes: lootboxes ?? 0,
        Renown: renown ?? 0,
      };
    });
  };

  if (loading) {
    return <div>Loading balance chart data...</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  if (!chartData || chartData.length === 0) {
    return <div></div>; // Display this when no chart data is available
  }

  return (
    <div>
      {/* Container for the buttons */}
      <div className="button-container">
        {chartData?.map((chart: any) => (
          <button
            key={chart.label}
            onClick={() => setSelectedChart(chart.label)}
            className="chart-button"  // Use the class for buttons
          >
            {chart.label}
          </button>
        ))}
      </div>
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '5px', borderRadius: '6px', width: '106%' }}>
        <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.25)', marginBottom: '20px' }}>
          Balance Chart - {selectedChart}
        </div>
        <ResponsiveContainer width="100%" height={150} style={{ marginLeft: '-10px', marginBottom: '0px' }}>
          <LineChart data={getChartData()} margin={{ top: 5, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" tick={{ fill: 'black', fontSize: 10 }} />
            <YAxis tick={{ fill: 'black', fontSize: 10 }} tickFormatter={(tick) => `${tick}%`} />
            <Tooltip formatter={(value: number) => `${value}%`} />
            <Legend 
              formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}  // Set text color to black
              wrapperStyle={{ fontSize: '110%' }}
            />
            <Line type="monotone" dataKey="Lootboxes" stroke="rgba(0, 120, 0, 1)" strokeWidth={2.5} />
            <Line type="monotone" dataKey="Renown" stroke="rgba(230, 0, 0, 1)" strokeWidth={2.5} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Styling using JSX */}
      <style jsx>{`
        .button-container {
          display: flex;
          flex-direction: row;  /* Ensure the buttons are in a row */
          justify-content: flex-start;  /* Align buttons to the left */
          align-items: center;
          flex-wrap: nowrap;  /* Prevent wrapping to a new line */
          overflow-x: auto;  /* Allow horizontal scroll if needed */
          gap: 10px;  /* Space between buttons */
        }

        .chart-button {
          display: flex;
          align-items: center;
          font-size: 14px;
          background-color: rgba(255, 255, 255, 0.25);
          color: black;
          padding: 0px 8px;
          border-radius: 4px;
          cursor: pointer;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-right: solid 2px black;
          border-bottom: solid 2px black;
          border-left: none;
          border-top: none;
        }

        .chart-button:hover {
          background-color: rgba(255, 255, 255, 0.4); /* Add a hover effect */
        }
      `}</style>
    </div>
  );
};

export default BalanceChart;



























