import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import wl from './wl';
import {ethers} from "ethers";

function generateMerkle(whiteList: string[]): MerkleTree {
  const leafNodes = whiteList.map(add => keccak256(add));

  const merkletree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  return merkletree;
}

export function getProof(address: string) {
  const leaf = generateLeafFromAddress(address);
  return getMerkleTreeForGateway().getHexProof(leaf);
}


// TODO collection addresses
export function getMerkleTreeForGateway() {
  const addresses: string[] = [
    '0x747910b74d2651a06563c3182838eae4120f4277', // aov
    '0xa7f1462e0ecdeebdee4faf6681148ca96db78777', // smol
    '0x7480224ec2b98f28cee3740c80940a2f489bf352', // TELL
    '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4', // souls
    '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9', // knights
    '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9', // marauder
    '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d', // initiated knights
  ];

  return new MerkleTree(
      addresses.map((address: string) =>
          generateLeafFromAddress(ethers.utils.getAddress(address))
      ),
      keccak256,
      { sortPairs: true }
  );
}

export function generateLeafFromAddress(address: string): Buffer {
  return Buffer.from(
      ethers.utils.solidityKeccak256(['address'], [address]).slice(2),
      'hex'
  );
}
