import React, { useEffect, useRef } from 'react';
import { BattlePairType } from '../types/adventurer';
import { getAdventurerInfo, getSlotIcons } from '../utils/utils';

interface BattleTableProps {
  battlePairs: BattlePairType[];
  selectedIds: string[];
  onRowClick: (id: string) => void;
  equip: boolean;
  armory: boolean;
  image: boolean;
  handleSelectAll: () => void;
  checkedOpponentsCount: number;
  sortConfig: { key: string; direction: 'ascending' | 'descending' } | null;
  requestSort: (key: string) => void;
  setBattleTableRendered: (rendered: boolean) => void; // Add this prop
}

const BattleTable: React.FC<BattleTableProps> = ({
  battlePairs,
  selectedIds,
  onRowClick,
  equip,
  armory,
  image,
  handleSelectAll,
  checkedOpponentsCount,
  sortConfig,
  requestSort,
  setBattleTableRendered // Add this prop
}) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (tableRef.current) {
        setBattleTableRendered(true);
      }
    });

    if (tableRef.current) {
      observer.observe(tableRef.current, { childList: true, subtree: true });
    }

    return () => {
      if (tableRef.current) {
        observer.disconnect();
      }
    };
  }, [battlePairs, setBattleTableRendered]);

  const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));

  const handleRowClick = (id: string, attackEpoch: number) => {
    if (Number(attackEpoch) === Number(currentEpoch)) {
      return;
    }
    onRowClick(id);
  };

  const handleSelectAllClick = () => {
    const validIds = battlePairs
      .filter(pair => Number(pair!.adventurer.attackEpoch) !== Number(currentEpoch))
      .map(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`);
    handleSelectAll();
  };

  return (
    <div className="table-container" ref={tableRef}>
      <table>
      <thead>
        <tr>
          <th colSpan={image ? 16 : 14}>
            Summary: ({battlePairs.length} adventurers,{' '}
            {battlePairs.filter(pair => Number(pair!.adventurer.attackEpoch) !== Number(currentEpoch)).length} ready for battle)
          </th>
          <th>{battlePairs.reduce((acc, pair) => acc + pair!.battle.lootboxChance, 0).toFixed(2)}</th>
          <th>{battlePairs.reduce((acc, pair) => acc + pair!.battle.lootboxValue, 0).toFixed(4)}</th>
          <th>{battlePairs.reduce((acc, pair) => acc + pair!.battle.animaRewardExpected, 0).toFixed(2)}</th>
          <th>{battlePairs.reduce((acc, pair) => acc + pair!.battle.usdRewardExpected, 0).toFixed(2)}</th>
          <th>{battlePairs.reduce((acc, pair) => acc + pair!.battle.renownRewardExpected, 0).toFixed(2)}</th>
        </tr>
        <tr>
          <th colSpan={image ? 9 : 8}>Adventurer</th> {/* Increased by 1 to account for Status */}
          <th colSpan={image ? 6 : 5}>Opponent</th> {/* Decreased by 1 */}
          <th colSpan={6}>Estimated Battle Results</th>
        </tr>
        <tr>
          <th>No.</th>
          {image && <th></th>}
          <th 
            onClick={() => requestSort('id')} 
            style={{ cursor: 'pointer', background: sortConfig?.key === 'id' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              ID&nbsp;
              {sortConfig?.key === 'id' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          <th>Type</th>
          <th 
            onClick={() => requestSort('bp')} 
            style={{ cursor: 'pointer', background: sortConfig?.key === 'bp' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              BP&nbsp;
              {sortConfig?.key === 'bp' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          <th 
            onClick={() => requestSort('renown')} 
            style={{ cursor: 'pointer', background: sortConfig?.key === 'renown' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              Renown&nbsp;
              {sortConfig?.key === 'renown' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          <th 
            onClick={() => requestSort('level')} 
            style={{ cursor: 'pointer', background: sortConfig?.key === 'level' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              Level&nbsp;
              {sortConfig?.key === 'level' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          <th
            onClick={() => requestSort('slots')} // Add sorting for Status
            style={{ cursor: 'pointer', background: sortConfig?.key === 'slots' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              Slots&nbsp;
              {sortConfig?.key === 'slots' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          <th
            onClick={() => requestSort('status')} // Add sorting for Status
            style={{ cursor: 'pointer', background: sortConfig?.key === 'status' ? '#c3c356' : '' }}
          >
            <div className="sortable-column">
              Status&nbsp;
              {sortConfig?.key === 'status' ? (
                sortConfig.direction === 'ascending' ? '↑' : '↓'
              ) : (
                '↕'
              )}
            </div>
          </th>
          {image && <th></th>}
          <th>ID</th>
          <th>Type</th>
          <th>BP</th>
          <th>Renown</th>
          <th>Level</th>
          <th>Win Probability</th>
          <th>Lootbox Chance</th>
          <th>Lootbox Value (MAGIC)</th>
          <th>Anima Reward</th>
          <th>Total Profit (USD)</th>
          <th>Renown Reward</th>
        </tr>
      </thead>

      <tbody className="battle-table-body">
        {battlePairs.map((pair, index) => {
          const adventurerInfo = getAdventurerInfo(pair!.adventurer.address, pair!.adventurer.archetype);
          const opponentInfo = getAdventurerInfo(pair!.opponent.address, pair!.opponent.archetype);
          const adventurerSlots = getSlotIcons(pair!.adventurer, equip);
          const rowId = `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`;

          return (
            <tr key={index} onClick={() => handleRowClick(rowId, pair!.adventurer.attackEpoch)} className={selectedIds.includes(rowId) ? 'selected' : ''}>
              <td>{index + 1}</td>
              {image && (
                <td style={{ minWidth: '70px' }}>
                  <img src={adventurerInfo.imageUrl} alt={adventurerInfo.advType} title={adventurerInfo.advType} width="60" height="60" />
                </td>
              )}
              <td>{pair!.adventurer.tokenId}</td>
              <td>{adventurerInfo.advType}</td>
              <td>
                {Math.floor(pair!.adventurer.battlePower / 1000)}
                {equip ? (
                  Math.floor(pair!.adventurer.battlePowerEquip / 1000) !== Math.floor(pair!.adventurer.battlePower / 1000) && (
                    <>
                      <br />
                      <span
                        style={{
                          color:
                            Math.abs(Math.floor(pair!.adventurer.battlePower / 1000) - Math.floor(pair!.adventurer.battlePowerEquip / 1000)) <= 50
                              ? 'inherit'
                              : Math.floor(pair!.adventurer.battlePower / 1000) > Math.floor(pair!.adventurer.battlePowerEquip / 1000) + 50
                              ? 'red'
                              : 'darkgreen',
                          fontSize: 'smaller',
                        }}
                      >
                        {Math.abs(Math.floor(pair!.adventurer.battlePower / 1000) - Math.floor(pair!.adventurer.battlePowerEquip / 1000)) > 50 && (
                          `(${Math.floor(pair!.adventurer.battlePowerEquip / 1000)})`
                        )}
                      </span>
                    </>
                  )
                ) : (
                  Math.floor(pair!.adventurer.battlePowerAdjusted / 1000) !== Math.floor(pair!.adventurer.battlePower / 1000) && (
                    <>
                      <br />
                      <span
                        style={{
                          color:
                            Math.abs(Math.floor(pair!.adventurer.battlePower / 1000) - Math.floor(pair!.adventurer.battlePowerAdjusted / 1000)) <= 50
                              ? 'inherit'
                              : Math.floor(pair!.adventurer.battlePower / 1000) > Math.floor(pair!.adventurer.battlePowerAdjusted / 1000) + 50
                              ? 'red'
                              : 'darkgreen',
                          fontSize: 'smaller'
                        }}
                      >
                        ({Math.floor(pair!.adventurer.battlePowerAdjusted / 1000)})
                      </span>
                    </>
                  )
                )}
              </td>
              <td>{Math.floor(pair!.adventurer.renown / 1000)}</td>
              <td>{pair!.adventurer.level}</td>
              <td className="tooltip"
                style={{
                  verticalAlign: 'middle',
                  display: 'table-cell'
                }}
              >
                {adventurerSlots}
                <span className="tooltiptext">
                  <div><span style={{ color: 'green' }}>🟩</span> equipped</div>
                  <div><span style={{ color: 'green' }}>🟨</span> equipped, by checkbox</div>
                  <div><span style={{ color: 'green' }}>🟦</span> equipped, from wallet</div>
                  <div><span style={{ color: 'black' }}>⬛</span> empty, equip available</div>
                  <div><span style={{ color: 'red' }}>🟥</span> empty, crafting or peripherals from wallet required</div>
                </span>
              </td>
              <td
                style={image
                  ? { textAlign: 'center', fontSize: '35px', lineHeight: '50px', width: '70px' }
                  : { textAlign: 'center' }
                }
              >
                {Number(pair!.adventurer.attackEpoch) === Number(currentEpoch) ? '❌' : '⚔️'}
              </td> {/* Status moved here */}
              {image && (
                <td style={{ minWidth: '70px' }}>
                  <img src={opponentInfo.imageUrl} alt={opponentInfo.advType} title={opponentInfo.advType} width="60" height="60" />
                </td>
              )}
              <td>{pair!.opponent.tokenId}</td>
              <td>{opponentInfo.advType}</td>
              <td>{Math.floor(pair!.opponent.battlePower / 1000)}</td>
              <td>{Math.floor(pair!.opponent.renown / 1000)}</td>
              <td>{pair!.opponent.level}</td>
              <td>{(pair!.battle.winProbability * 100).toFixed(0)}%</td>
              <td>{(pair!.battle.lootboxChance * 100).toFixed(0)}%</td>
              <td>{pair!.battle.lootboxValue.toFixed(4)}</td>
              <td>{pair!.battle.animaRewardExpected.toFixed(2)}</td>
              <td>{pair!.battle.usdRewardExpected.toFixed(2)}</td>
              <td>{pair!.battle.renownRewardExpected.toFixed(2)}</td>
            </tr>
          );
        })}
      </tbody>

        <tfoot>
          <tr>
            <th colSpan={21}>&nbsp;</th>
          </tr>
        </tfoot>
      </table>
      <style jsx>{`
        .table-container {
          position: relative;
          overflow: hidden;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        tbody {
          max-height: calc(100% - 20px);
          overflow-y: auto;
        }
        tbody tr {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.4);
        }
        thead, tfoot {
          background: #a26f3d;
        }
        thead {
          position: sticky;
          top: 0;
        }
        tfoot {
          font-size: 7px;
        }
        th, td {
          border: 1px solid #a26f3d;
          padding: 2px;
          text-align: center;
        }
        thead th, tfoot th {
          background-color: #a26f3d;
          border-color: #c3c356;
        }

        .sortable:hover {
          background-color: #c3c356;
        }

        img {
          width: 60px;
          height: 60px;
        }
        td {
          text-align: center;
        }
        ::-webkit-scrollbar {
          width: 18px;
          height: 18px;
        }
        ::-webkit-scrollbar-track {
          background: #a26f3d;
        }
        ::-webkit-scrollbar-thumb {
          background: #c3c356;
          border-radius: 6px;
        }
        tr.selected {
          background-color: rgba(240, 230, 140, 0.7) !important;
        }
        button {
          height: 24px;
          background-color: #666;
          color: white;
          border-radius: 4px;
          cursor: pointer;
          width: 130px;
        }
        button:hover {
          background-color: #555;
        }
        .slot-icons {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2px;
        }
        .slot-icon {
          font-size: 8px;
          line-height: 1;
          background-color: black;
        }
        .tooltip {
          position: relative;
          display: inline-block;
        }
        .tooltip .tooltiptext {
          visibility: hidden;
          width: 260px;
          font-size: 10px;
          background-color: #c3c356;
          color: #000;
          text-align: left;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 100%;
          left: 50%;
          margin-left: -100px;
          opacity: 0;
          transition: opacity 0.3s;
        }
        .tooltip:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
        .sortable-column {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .sortable-column .tooltip {
          margin-left: 5px;
        }
      `}</style>
    </div>
  );
};

export default BattleTable;







  











