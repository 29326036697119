import axios from 'axios';
import { AdventurerType } from '../types/adventurer';
import { currentEpoch } from '../utils/utils';
import { ALLOWED_ADDRESSES } from '../../../pages/index';  // Import the ALLOWED_ADDRESSES
import { API_BASE_URL } from '../constants/constants';
//const API_BASE_URL='https://staging.getanima.org';
const graphEndpoint = API_BASE_URL;

export const loadOpponentsData = async (ownerAddress: string): Promise<AdventurerType[]> => {
  const pageSize = 1000;
  let offset = 0;
  let hasMoreData = true;
  let allData: AdventurerType[] = [];
  let addressLowerCase = ownerAddress ? (`0x${ownerAddress.slice(2).toLowerCase()}` as `0x${string}`) : undefined;

  

  while (hasMoreData) {
    try {
      const response = await axios.post(graphEndpoint, {
        operationName: 'Adventurers',
        query: `
          query Adventurers($first: Int!, $skip: Int!, $owner: String!) {
            adventurers(first: $first, skip: $skip, where: {
              battlePower_not: 0,
              renown_not: 0,
              owner_not_in:["0x0000000000000000000000000000000000000000", 
              "0x000000000000000000000000000000000000dead"],
              attackEpoch_gte: "${currentEpoch()-7}",
              attackEpoch_lte: "${currentEpoch()}",
              owner_not: "${addressLowerCase}"
            }) {
              id
              address
              tokenId
              createdAt
              owner
              archetype
              profession
              klass
              level
              xp
              hp
              battles
              strength
              dexterity
              intelligence
              charisma
              constitution
              wisdom
              lastBattledAt
              unbound
              renown
              battlePower
              slot1
              slot2
              slot3
              slot4
              slot1Broken
              slot2Broken
              slot3Broken
              slot4Broken
              openSlots
              attackEpoch
            }
          }
        `,
        variables: {
          first: pageSize,
          skip: offset,
          owner: ownerAddress,
        },
      });

      const responseData = response.data;

      if (responseData && responseData.data && responseData.data.adventurers) {
        const fetchedData = responseData.data.adventurers;

        if (fetchedData.length < pageSize) {
          hasMoreData = false;
        }

        allData = [...allData, ...fetchedData];
        offset += pageSize;
      } else {
        console.error('Unexpected GraphQL response structure:', responseData);
        hasMoreData = false;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      hasMoreData = false;
    }
  }




  // Check if ownerAddress is in ALLOWED_ADDRESSES with group 'Mafia'
  const isMafiaOwner = ALLOWED_ADDRESSES.some(
    entry => entry.address.toLowerCase() === ownerAddress.toLowerCase() && entry.group === 'Mafia'
  );


  // Only filter data if ownerAddress belongs to the Mafia group
  if (isMafiaOwner) {
    allData = allData.filter(adventurer => {
      const matchingRecord = ALLOWED_ADDRESSES.find(
        entry => entry.address.toLowerCase() === adventurer.owner.toLowerCase() && entry.group === 'Mafia'
      );
      return !matchingRecord; // Keep records where there is no matching Mafia record
    });
  }


  return allData;
};

