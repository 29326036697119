import { ConnectButton } from '@rainbow-me/rainbowkit';
import Link from 'next/link';
import AnimaBalanceDisplay from '../../dai/components/AnimaBalanceDisplay'; // Import the AnimaBalanceDisplay component

export function Header() {
  return (
    <div>
      <div className="header-wrapper">
        <div className="header">
          <div className="left">
            <h1>
              <Link href="/" title="Home page">
                <div style={{ marginLeft: '5px', color: 'white' }}>
                  <h1 style={{ color: 'white' }}>MAFIA AutoBattler ©AM v1.5</h1>
                </div>
              </Link>
            </h1>
          </div>
          <div className="right">
            <AnimaBalanceDisplay /> {/* Add the AnimaBalanceDisplay component */}
            <div className="login-box">
              <ConnectButton
                accountStatus={{
                  smallScreen: 'avatar',
                  largeScreen: 'full'
                }}
                showBalance={false} // Hide the ETH balance
                chainStatus="none" // Hide the network status to prevent changing
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .header-wrapper {
          text-transform: uppercase;
          position: fixed;
          top: 0;
          width: 100%;
          left: 0;
          z-index: 1000;
        }

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          flex-wrap: wrap;
          background: var(--black-background);
          padding: 5px;
        }

        .links {
          display: flex;
          justify-content: space-between;
          max-width: 800px;
          margin: 0 auto;
          font-size: 12px;
          padding-left: 15px;
          padding-right: 15px;
          flex-wrap: wrap;
          align-items: center;
        }

        .link {
          margin-left: 15px;
          margin-right: 15px;
          font-size: 12px;
        }

        a {
          color: white;
        }

        h1 {
          padding: 0;
          margin: 0;
          font-size: 14px;
          text-transform: none;
        }

        h1 a {
          color: white;
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        @media only screen and (max-width: 600px) {
          h1 img {
            max-width: 100px;
          }

          .header .links {
            display: none;
          }

         
        }

        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .network {
          pointer-events: none; /* Disable clicking */
        }
      `}</style>
    </div>
  );
}




