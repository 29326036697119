import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { getAdventurers } from '../api/getAdventurers';
import { AdventurerType, OpponentType, Battles, BattlePairType } from '../types/adventurer';
//import ModeSelector from './ModeSelector';
import BattleTable from './BattleTable';
import { PERIPHERALS } from '../constants/constants';
import { calculateBattlePower } from '../api/getAdventurers';
import { useRarityItemBalance } from '../../dai/context/RarityItemBalance.context';
import { 
  calculateWinProbability, 
  calculateLootboxChance, 
  calculateLootBoxValue, 
  calculateAnimaRewardExpected, 
  calculateUsdRewardExpected, 
  calculateRenownRewardExpected,
  selectOpponent
} from '../utils/utils';

//export type ModeType: string;




const BattleList = ({
  address,
  opponentsData,
  cryptoPrices,
  lootboxPrices,
  selectedIds,
  onSelectionChange,
  setBattles,
  forceRefresh,
  setCalculating,
  armory,
  onEquipChange,
  onArmoryChange,
  aov,
  mrdr,
  smol,
  toe,
  life,
  kote,
  onlyReadyForBattle,
  setBattleTableRendered,
  lpBoostValue // Receive lpBoostValue as a prop
}: {
  address: string;
  opponentsData: OpponentType[];
  cryptoPrices: any;
  lootboxPrices: any;
  selectedIds: string[];
  onSelectionChange: (ids: string[], equip: boolean, armory: boolean, image: boolean) => void;
  setBattles: (battles: Battles) => void;
  forceRefresh: boolean;
  adventurers: AdventurerType[];
  setCalculating: (calculating: boolean) => void;
  equip: boolean;
  armory: boolean;
  onEquipChange: (checked: boolean) => void;
  onArmoryChange: (checked: boolean) => void;
  aov: boolean;
  mrdr: boolean;
  smol: boolean;
  toe: boolean;
  life: boolean;
  kote: boolean;
  onlyReadyForBattle: boolean;
  setBattleTableRendered: (rendered: boolean) => void;
  lpBoostValue: number | null; // Type for lpBoostValue prop
}) => {
  const [balanceValue, setbalanceValue] = useState<number>(2.00); // Default to 1.00

  const [image, setImage] = useState(false);
  const [adventurers, setAdventurers] = useState<AdventurerType[]>([]);
  const [mode, setMode] = useState('2.0');
  const [battlePairs, setBattlePairs] = useState<BattlePairType[]>([]);
  const [checkedOpponentsCount, setCheckedOpponentsCount] = useState<number>(0);
  const usedOpponents = useMemo(() => new Set<string>(), []);
  const [originalAdventurers, setOriginalAdventurers] = useState<AdventurerType[]>([]);
  const { balances: rarityItemBalances, setBalances: setRarityItemBalances } = useRarityItemBalance();
  const [originalBalances, setOriginalBalances] = useState<any>({});
  const [equip, setEquip] = useState<boolean>(true); // Define equip state here
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));

  const isSlotOccupied = (slot: String | Number) => {
    if (slot === undefined || Number(slot) === 0) return 0;
    return 1
  }

  const getFilteredAndSortedAdventurers = () => {
    let filteredAdventurers = adventurers;

    // Apply filtering
    if (!aov) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x747910b74d2651a06563c3182838eae4120f4277');
    if (!mrdr) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9');
    if (!smol) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xa7f1462e0ecdeebdee4faf6681148ca96db78777');
    if (!toe) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0x7480224ec2b98f28cee3740c80940a2f489bf352');
    if (!life) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4');
    if (!kote) filteredAdventurers = filteredAdventurers.filter(adv => adv.address !== '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9' && adv.address !== '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d');
   
   
   
    if (onlyReadyForBattle) {
      filteredAdventurers = filteredAdventurers.filter(adv => Number(adv.attackEpoch) !== currentEpoch);
    }


    // Apply sorting
    if (sortConfig !== null) {
      filteredAdventurers = filteredAdventurers.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'level':
            aValue = Number(a.level);
            bValue = Number(b.level);
            break;
          case 'bp':
            aValue = Math.floor(a.battlePower / 1000);
            bValue = Math.floor(b.battlePower / 1000);
            break;
          case 'renown':
            aValue = Math.floor(a.renown / 1000);
            bValue = Math.floor(b.renown / 1000);
            break;
          case 'id':
            aValue = Number(a.tokenId);
            bValue = Number(b.tokenId);
            break;
          case 'status': // Sorting by Status
            aValue = Number(a.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0; // Not ready vs. Ready
            bValue = Number(b.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0;
            break;
          
          case 'slots':
            aValue = isSlotOccupied(a.slot1) + isSlotOccupied(a.slot2)+ isSlotOccupied(a.slot3)+ isSlotOccupied(a.slot4) + isSlotOccupied(a.slot1equip) + isSlotOccupied(a.slot2equip)+ isSlotOccupied(a.slot3equip)+ isSlotOccupied(a.slot4equip);
            bValue = isSlotOccupied(b.slot1) + isSlotOccupied(b.slot2)+ isSlotOccupied(b.slot3)+ isSlotOccupied(b.slot4) + isSlotOccupied(b.slot1equip) + isSlotOccupied(b.slot2equip)+ isSlotOccupied(b.slot3equip)+ isSlotOccupied(b.slot4equip);
            break;

          default:
            aValue = a;
            bValue = b;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredAdventurers;
  };

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setEquip(checked);
    setCalculating(true);
    onEquipChange(checked);
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image); // Clear selections

    const filteredAdventurers = sortedAdventurers; // Using only filtering, order is irrelevant here
    recalculateBattlePairsWithEquip(filteredAdventurers, checked);
  };

  const recalculateBattlePairsWithEquip = (adventurers: AdventurerType[], equip: boolean) => {
    const countRef = { current: 0 };
    const newBattlePairs = adventurers.map(adventurer => {
      const opponent = selectOpponent(mode, adventurer, opponentsData, usedOpponents, lootboxPrices, cryptoPrices, countRef, equip,lpBoostValue || 0);
      if (!opponent) {
        return null;
      }
      const battle = {
        winProbability: calculateWinProbability(adventurer, opponent, equip),
        lootboxChance: calculateLootboxChance(adventurer, opponent, equip),
        lootboxValue: calculateLootBoxValue(adventurer, opponent, equip, lootboxPrices, cryptoPrices),
        animaRewardExpected: calculateAnimaRewardExpected(adventurer, opponent, equip, lpBoostValue || 0),
        usdRewardExpected: calculateUsdRewardExpected(adventurer, opponent, equip, lootboxPrices, cryptoPrices, lpBoostValue || 0),
        renownRewardExpected: calculateRenownRewardExpected(adventurer, opponent, equip)
      };
      return { adventurer, opponent, battle, equip };
    }).filter(pair => pair !== null) as BattlePairType[];
    setBattlePairs(newBattlePairs);
    setCheckedOpponentsCount(countRef.current);
    const battlesFromBattleList: Battles = newBattlePairs.reduce((acc, pair) => {
      const key = `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`;
      acc[key] = {
        adventurer: pair!.adventurer,
        opponent: pair!.opponent,
        results: pair!.battle,
      };
      return acc;
    }, {} as Battles);
    setBattles(battlesFromBattleList);

    const minDelay = 500; // Minimum delay of 1000ms (1 second)
    const maxDelay = 6000; // Maximum delay of 5000ms (5 seconds)
    // Calculate a reasonable delay based on the number of adventurers
   
    const delay = Math.min(minDelay + Number(adventurers.length) * 10, maxDelay); // Max delay of 10 seconds
    console.log('adventurers length:',Number(adventurers.length),' delay:',delay);
    setTimeout(() => {
      setCalculating(false);
    }, delay);
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalculating(true);
    setImage(event.target.checked);
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image); // Clear selections
  };


  const validRarity = (rType:string, address: string,level: number) => {
    if (address === '0x747910b74d2651a06563c3182838eae4120f4277'  && level>= 31 && rType === 'Epic') return true;
    else if (address === '0x747910b74d2651a06563c3182838eae4120f4277' && level>=15 && rType === 'Rare') return true;
    else if (rType === 'Common') return true;
    else return false;
  }

  const handlePeriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
  
    if (checked) {
      setOriginalAdventurers([...adventurers]);
      setOriginalBalances({ ...rarityItemBalances });
  
      const filteredAdventurers = getFilteredAndSortedAdventurers();
  
      const updatedAdventurers = filteredAdventurers.map(adv => ({ ...adv }));
      let localBalances = { ...rarityItemBalances };
  
      updatedAdventurers.forEach(adv => {
        const addEquipmentToArmoryIds: number[] = [];
        const addEquipmentToArmoryAmounts: number[] = [];
        let peripheralAdded = false;
  
        ['slot1', 'slot2', 'slot3', 'slot4'].forEach((slot, index) => {
          if (adv[slot as keyof AdventurerType] === 0 && adv[`slot${index + 1}equip` as keyof AdventurerType] === 0) {
            const slotType = ['Head', 'Chest', 'Hand', 'Accessory'][index];
  
            // Define rarity priorities
            const rarityPriority = ['Legendary', 'Epic', 'Rare', 'Common'];
  
            // Try each rarity in the priority order
            let suitablePeripheralId: string | undefined;
            for (const rarity of rarityPriority) {
              suitablePeripheralId = Object.keys(localBalances).find(id => {
                const item = PERIPHERALS[Number(id)];
                return item && 
                  item.attributes.some(attr => attr.trait_type === 'Slot' && attr.value === slotType) &&
                  item.attributes.some(attr => attr.trait_type === 'Rarity' && attr.value === rarity && validRarity(attr.value, adv['address'], adv['level'])) &&
                  localBalances[Number(id)] > 0;
              });
  
              // If a suitable peripheral is found for this rarity, break the loop
              if (suitablePeripheralId) break;
            }
  
            if (suitablePeripheralId) {
              const peripheralId = Number(suitablePeripheralId);
  
              // Safe check to ensure peripheral exists
              const peripheral = PERIPHERALS[peripheralId];
              if (peripheral) {
                (adv as any)[slot] = peripheralId;
                (adv as any)[`${slot}Rarity`] = peripheral.attributes.find(attr => attr.trait_type === 'Rarity')?.value;
                localBalances[peripheralId] -= 1;
  
                if (localBalances[peripheralId] === 0) {
                  delete localBalances[peripheralId];
                }
  
                addEquipmentToArmoryIds.push(peripheralId);
                addEquipmentToArmoryAmounts.push(1);
                peripheralAdded = true;
              }
            }
          }
        });
  
        adv.addEquipmentToArmoryIds = addEquipmentToArmoryIds;
        adv.addEquipmentToArmoryAmounts = addEquipmentToArmoryAmounts;
  
        if (peripheralAdded) {
          adv.battlePowerAdjusted = Math.round(calculateBattlePower(adv, false) * 1000);
          adv.battlePowerEquip = Math.round(calculateBattlePower(adv, true) * 1000);
          if (adv.battlePowerAdjusted > adv.battlePowerEquip) adv.battlePowerEquip = adv.battlePowerAdjusted;
        }
      });
  
      localBalances = Object.fromEntries(Object.entries(localBalances).filter(([_, amount]) => amount > 0));
  
      setAdventurers(updatedAdventurers);
      setRarityItemBalances(localBalances);
  
      onArmoryChange(checked);
    } else {
      setAdventurers([...originalAdventurers]);
      setRarityItemBalances({ ...originalBalances });
    }
  
    usedOpponents.clear();
    onSelectionChange([], equip, armory, image);
  };
  
  
  

  const handleModeChange = useCallback((newMode: string) => {
    console.log("Balance mode changed to:", newMode);
    setTimeout(() => {
      setCalculating(true);
    }, 0); // Small delay to ensure state update is processed
    usedOpponents.clear();
    setMode(newMode); // Set the new balance value
    onSelectionChange([], equip, armory, image); // Clear selections
  }, [setCalculating, usedOpponents, setMode, onSelectionChange, equip, armory, image]);
  

  useEffect(() => {
    const fetchAdventurers = async () => {
      const adv = await getAdventurers(address);
      setAdventurers(adv);
    };
    fetchAdventurers();
  }, [address]);

  useEffect(() => {
    if (forceRefresh) {
      const fetchAdventurers = async () => {
        const adv = await getAdventurers(address);
        setAdventurers(adv);
        onSelectionChange([], equip, armory, image); // Clear selections and pass equip armory
        usedOpponents.clear(); // Clear used opponents after refreshing adventurers
      };
      fetchAdventurers();
    }
  }, [forceRefresh, address, onSelectionChange, equip, armory, image]);

  const sortedAdventurers = useMemo(() => {
    if (sortConfig !== null) {
      return [...adventurers].sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'level':
            aValue = Number(a.level);
            bValue = Number(b.level);
            break;
          case 'bp':
            aValue = Math.floor(a.battlePower / 1000);
            bValue = Math.floor(b.battlePower / 1000);
            break;
          case 'renown':
            aValue = Math.floor(a.renown / 1000);
            bValue = Math.floor(b.renown / 1000);
            break;
          case 'id':
            aValue = Number(a.tokenId);
            bValue = Number(b.tokenId);
            break;
          case 'status': // Sorting by Status
            aValue = Number(a.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0; // Not ready vs. Ready
            bValue = Number(b.attackEpoch) === Math.floor(Date.now() / (24 * 60 * 60 * 1000)) ? 1 : 0;
            break;
          case 'slots':
            aValue = isSlotOccupied(a.slot1) + isSlotOccupied(a.slot2)+ isSlotOccupied(a.slot3)+ isSlotOccupied(a.slot4) + isSlotOccupied(a.slot1equip) + isSlotOccupied(a.slot2equip)+ isSlotOccupied(a.slot3equip)+ isSlotOccupied(a.slot4equip);
            bValue = isSlotOccupied(b.slot1) + isSlotOccupied(b.slot2)+ isSlotOccupied(b.slot3)+ isSlotOccupied(b.slot4) + isSlotOccupied(b.slot1equip) + isSlotOccupied(b.slot2equip)+ isSlotOccupied(b.slot3equip)+ isSlotOccupied(b.slot4equip);
            break;
          default:
            aValue = a;
            bValue = b;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return adventurers;
  }, [adventurers, sortConfig]);

  const filteredAdventurers = useMemo(() => {
  
    console.log("Adventurers state:", adventurers); // Debugging line
    console.log("Checkbox states - AOV:", aov, "MRDR:", mrdr, "SMOL:", smol, "TOE:", toe, "LIFE:", life, "KOTE:", kote); // Debugging line
    
    const filtered = sortedAdventurers.filter(adv => {
      if (!aov && adv.address === '0x747910b74d2651a06563c3182838eae4120f4277') return false;
      if (!mrdr && adv.address === '0x6b157ecab373a32c77c5f1084ebfb57d611c13f9') return false;
      if (!smol && adv.address === '0xa7f1462e0ecdeebdee4faf6681148ca96db78777') return false;
      if (!toe && adv.address === '0x7480224ec2b98f28cee3740c80940a2f489bf352') return false;
      if (!life && adv.address === '0xdc758b92c7311280aeeb48096a3bf4d1c1f936d4') return false;
      if (!kote && (adv.address === '0xb52d71c3dde0cee0fad2dce0a9ca33fdfe06aec9' || adv.address === '0xfd2b634dc78ece6f240540b0556725fc5ec4bd7d')) return false;
      
      // Update this logic for "Only ready for battle" filter
      if (onlyReadyForBattle && Number(adv.attackEpoch) === currentEpoch) return false;
  
      return true;
    });
  
    console.log("Filtered adventurers:", filtered); // Debugging line
    return filtered;
  }, [sortedAdventurers, aov, mrdr, smol, toe, life, kote, onlyReadyForBattle, currentEpoch]);
  

  useEffect(() => {
    usedOpponents.clear(); // Clear used opponents before recalculating
    recalculateBattlePairsWithEquip(filteredAdventurers, equip);
  }, [filteredAdventurers, mode, opponentsData, lootboxPrices, cryptoPrices, equip, image]);

  const handleRowClick = useCallback((id: string) => {
    const adventurer = battlePairs.find(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}` === id)?.adventurer;
    if (adventurer && adventurer.attackEpoch === Math.floor(Date.now() / (24 * 60 * 60 * 1000))) {
      return;
    }
    const updatedSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter(selectedId => selectedId !== id)
      : [...selectedIds, id];
    onSelectionChange(updatedSelectedIds, equip, armory, image); // Przekazywanie equip
  }, [selectedIds, battlePairs, onSelectionChange, equip, armory, image]);

  const handleSelectAll = useCallback(() => {
    const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
    const validPairs = battlePairs.filter(pair => Number(pair!.adventurer.attackEpoch) !== Number(currentEpoch));
    const validIds = validPairs.map(pair => `${pair!.adventurer.address}-${pair!.adventurer.tokenId.toString()}`);
    const updatedSelectedIds = selectedIds.length === validIds.length ? [] : validIds;
    onSelectionChange(updatedSelectedIds, equip, armory, image); // Przekazywanie equip
  }, [selectedIds, battlePairs, onSelectionChange, equip, armory, image]);

  const handleBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setbalanceValue(newValue); // Just update the value of the slider as it's moved
  };


  const handleSliderRelease = () => {
    // This is called when the user releases the slider
    console.log('Slider released, updating opponents with focus value:', balanceValue);
    handleModeChange(balanceValue.toFixed(2)); // Trigger opponent selection logic based on focus value
  };

  return (
    <div className="battle-list-wrapper">
      <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
        <div>Opponents in the arena: {checkedOpponentsCount}</div>
        
        
        


        <div><button className="toggle-all" onClick={handleSelectAll}>Toggle all</button></div>
        <div className="equip-container">
          <label className="equip-label">
            Equip if available
            <input id="equip" type="checkbox" onChange={handleCheckboxChange} checked={equip} className="equip-checkbox" />
          </label>
        </div>
        <div className="equip-container">
          <label className="equip-label">
            Use peripherals from wallet
            <input id="peri" type="checkbox" onChange={handlePeriChange} className="equip-checkbox" />
          </label>
        </div>
        <div className="equip-container">
          <label className="equip-label">
            Show avatars
            <input id="avatar" type="checkbox" onChange={handleAvatarChange} className="equip-checkbox" />
          </label>
        </div>
        <div className="slider-container" >&nbsp;
        </div>
        <div className="equip-container">
        <label className="tooltip-label" >
          Balance: {balanceValue.toFixed(2)}
            <div className="tooltip-text">
            0.00 - max Lootbox Chance <br /> 1.00 - max USD Profit <br /> 2.00 - max Renown
            </div>
            <div className="tooltip-container">
              <input
                id="balance"
                type="range"
                min="0.00"
                max="2.00"
                step="0.05"
                value={balanceValue}
                onChange={handleBalanceChange} // Update the slider's value
                onMouseUp={handleSliderRelease} // Trigger action when user releases the slider
                onTouchEnd={handleSliderRelease} // Mobile touch event support
                className="gauge-slider"
              />
              
            </div>
            
          </label>
        </div>


      </div>
      <BattleTable 
        battlePairs={battlePairs} 
        selectedIds={selectedIds} 
        onRowClick={handleRowClick} 
        equip={equip}
        armory={armory}
        image={image}
        handleSelectAll={handleSelectAll}
        checkedOpponentsCount={checkedOpponentsCount}
        sortConfig={sortConfig}
        requestSort={requestSort}
        setBattleTableRendered={setBattleTableRendered} // Pass the prop
      />
      <style jsx>{`
        .battle-list-wrapper {
          position: relative;
          z-index: 10; /* Ustawienie niższego z-index */
        }
        .toggle-all {
          height: 24px;
          background-color: #666;
          color: white;
          border-radius: 4px;
          cursor: pointer;
          width: 130px;
        }

        .toggle-all:hover {
          background-color: #555;
        }

        .reload {
          background-color: #666;
          color: white;
          cursor: pointer;
          font-size: 24px;
        }
        
        .treload:hover {
          background-color: #555;
        }

        .equip-container {
          display: flex;
          align-items: center;
        }
        
        .slider-container {
          display: flex;
          align-items: center;
          width: 40px;
        }
       
        .equip-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          background-color: #666;
          color: white;
          padding: 2px 8px;
          border-radius: 4px;
          cursor: pointer;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-right: solid 2px black;
          border-bottom: solid 2px black;
          border-left: solid 2px #555;
          border-top: solid 2px #555;
        }

        .equip-checkbox {
          transform: scale(1.5);
          margin-left: 8px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: #66cc66; /* Ustawienie tła na taki sam odcień zielonego */
          border: 2px solid #555;
          border-radius: 4px;
          width: 16px; /* Dostosuj rozmiar */
          height: 16px; /* Dostosuj rozmiar */
          cursor: pointer;
          position: relative;
        }
        .equip-checkbox:checked::after {
          content: '✓'; /* Znak zaznaczenia */
          color: white; /* Kolor znaku zaznaczenia */
          font-size: 10px; /* Zmniejszenie rozmiaru znaku */
          position: absolute;
          top: -2px;
          left: 2px;
        }
        .equip-checkbox:checked {
          background-color: #66cc66; /* Ustawienie tła na taki sam odcień zielonego po zaznaczeniu */
        }


        .gauge-slider {
          appearance: none;
          width: 190px;
          height: 8px;
          background: #444;
          border-radius: 5px;
          outline: none;
          cursor: pointer;
        }

        .gauge-slider::-webkit-slider-thumb {
          appearance: none;
          width: 16px;
          height: 16px;
          background: #66cc66;
          border-radius: 50%;
          border: 2px solid #555;
          cursor: pointer;
        }

        .gauge-slider::-moz-range-thumb {
          width: 16px;
          height: 16px;
          background: #66cc66;
          border-radius: 50%;
          border: 2px solid #555;
          cursor: pointer;
        }

        .tooltip-container {
          position: relative;
          display: inline-block;
        }

        .tooltip-container:hover .tooltip-text {
          visibility: visible;
        }

        .tooltip-text {
          visibility: hidden;
          background-color: #c3c356;
          color: black;
          text-align: left;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
         
          left: 88%;
          bottom: 100%;
          transform: translateX(-50%);
          width: 200px;
          font-size: 11px;
          opacity: 0;
          transition: opacity 0.3s;
        }
        
        .tooltip-label{

        width: 120px; 
        }

        .tooltip-label:hover .tooltip-text {
          visibility: visible;
          opacity: 1;
        }

      `}</style>
    </div>
  );
};

export default React.memo(BattleList);





























  
  


